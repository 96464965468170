.outer {
  width: 335px;
  height: 596px;
  background-color: #fff;
}

.profileModal {
  position: absolute;
  right: 64px;
  top: 95px;
  border-radius: 32px;
}

.profileBox {
  padding: 16px;
  width: 100%;
  border-radius: 16px;
  background-color: #007fff;
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
}

.profileBox img {
  width: 80px;
  height: 80px;
}

.profileBox p {
  text-align: center;
  /* Text/Large/Semi Bold */
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 30px */
  color: #fff;
}

.profileBox h3 {
  text-align: center;
  /* Text/Regular/Medium */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  /* 24px */
  color: #fff;
}

.contactBox {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
  border-top: 1px solid #beceff;
  border-bottom: 1px solid #beceff;
}

.contactFlex {
  display: flex;
  align-items: center;
  gap: 14px;
}

.contactFlex img {
  width: 24px;
  height: 24px;
}

.contactFlex p {
  color: #444;
  /* Text/Regular/Semi Bold */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  /* 24px */
}

.profileinfo {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 24px;
}

.interestedBox {
  border-radius: 10px;
  border: 1px solid #beceff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
}

.interestedBoxSelected {
  background-color: #ECF0FF !important;
}

.selectedColor path {
  stroke: #007FFF !important;
}

.logoutBox {
  border-radius: 10px;
  border: 1px solid #ffbaba;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  background-color: #ffecec;
}

.insideBox {
  display: flex;
  gap: 16px;
}

.insideBox img {
  width: 28px;
  height: 28px;
}

.insideBox p {
  color: #222;
  /* Text/Regular/Normal */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.interestedBoxSelected .insideBox p {
  color: #007FFF !important;
  /* Text/Regular/Normal */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}


@media(max-width: 1000px) {
  .profileBox img {
    width: 52px;
    height: auto;
  }

  .profileBox p {
    font-size: 18px;
  }

  .profileBox h3 {
    font-size: 14px;
  }

  .profileBox {
    margin-bottom: 16px;
  }

  .contactBox {
    padding: 16px 0;
    gap: 10px;
  }

  .contactFlex p {
    font-size: 14px;
  }

  .insideBox p {
    font-size: 14px;
  }
}

@media(max-width: 650px) {

  .profileBox p {
    font-size: 14px;
  }

  .profileBox h3 {
    font-size: 12px;
  }

  .profileBox {
    margin-bottom: 16px;
  }


  .contactFlex p {
    font-size: 12px;
  }

  .insideBox p {
    font-size: 12px;
  }

  .profileModal {
    right: 0;
  }
}