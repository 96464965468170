.pageContainer {
    margin: 64px;
    display: flex;
}

.leftSection {
    width: 23%;
    padding-right: 32px;
    border-right: 1px solid#BECEFF;
}

.rightSection {
    flex: 1 0;
}

@media (max-width:1500px) {

    .leftSection {
        width: 30%;
    }
}

@media (max-width:1200px) {

    .leftSection {
        width: 34%;
    }
}

@media (max-width:1024px) {
    .pageContainer {
        margin: 40px 32px 40px 32px;
    }

    .leftSection {
        padding-right: 0;
    }
}

@media (max-width:668px) {
    .pageContainer {
        margin: 36px 20px;
    }
}