.loginOuter{
  padding: 20px;
  padding-top: 60px;
}

@media(max-width: 650px){
  .loginOuter{
  padding: 0;
  padding-top: 30px;
  }
}

