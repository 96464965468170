.invalidInput {
  /* background-color: rgb(239, 131, 84); */
  background-color: #09a541;
  color: white;
  text-align: center;
  border-radius: 25px;
  width: 100%;
  height: auto;
  padding: 0.8rem 0;
  margin: auto;
}
.invalidInput.yellowLabel {
  margin: auto;
  width: 70%;
  background-color: #007FFF;
}

.invalidInput.greenLabel {
  margin: auto;
  width: 70%;
  background-color: #09a541;
}
