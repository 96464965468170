.container {
    padding-left: 32px;
}

.headSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
}

.listingWrapper {
    margin-top: 64px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.title {
    color: #000000;

    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.flex {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.card {
    width: 100%;
    display: flex;
    padding: 16px;
    align-items: flex-start;
    gap: 32px;
    align-self: stretch;

    border-radius: 32px;
    border: 1px solid #BECEFF;
    background: #FFF;
}

.image {
    width: 31%;
    height: auto;
    border-radius: 20px;
    max-height: 250px;
}

.dataWrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
    flex: 1 0;
}

.vehicleName {
    color: #222222;

    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}

.gridData {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    width: 100%;
}

.gridData section {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.columnHead {
    color: #444444;

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.columnValue {
    color: #222222;

    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}


@media (max-width:1450px) {
    .image {
        width: 35%;
        height: auto;
        border-radius: 20px;
    }
}

@media (max-width:1350px) {
    .image {
        width: 38%;
        height: auto;
        border-radius: 20px;
    }
}

@media (max-width:1250px) {
    .image {
        width: 42%;
        height: auto;
        border-radius: 20px;
    }

    .gridData {
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
    }
}

@media (max-width:1024px) {
    .container {
        padding-left: 0;
    }

    .title {
        font-size: 18px;
    }

    .listingWrapper {
        gap: 16px;
    }

    .listingWrapper {
        margin-top: 32px;
    }

    .card {
        width: 100%;
        padding: 16px;
        gap: 16px;

        border-radius: 32px;
    }

    .dataWrapper {
        gap: 24px;
    }

    .image {
        width: 35%;
        height: auto;
    }

    .vehicleName {
        font-size: 18px;
    }

    .gridData {
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;
    }
}

@media (max-width:668px) {

    .title {
        font-size: 16px;
    }

    .card {
        width: 100%;
        flex-direction: column;
        padding: 8px;
        gap: 16px;

        border-radius: 24px;
    }

    .dataWrapper {
        padding: 10px;
        gap: 8px;
        width: 100%;
    }

    .image {
        width: 100%;
        height: auto;
    }

    .vehicleName {
        font-size: 14px;
    }

    .gridData {
        grid-template-columns: repeat(2, 1fr);
    }

    .columnHead {
        font-size: 12px;
    }

    .columnValue {
        font-size: 14px;
    }
}