.container {
    padding-left: 32px;
}

.headSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.formContainer {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    width: 528px;
}

.formText {
    color: #444444;

    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    margin-bottom: 48px;
}

.passwordInput {
    height: 57px;
}

.button {
    height: 44px;
    padding: 10px 20px;
    border-radius: 16px;

    /* color: #FFF; */
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: #007FFF !important;
    color: #FFFFFF !important;
}


@media (max-width:1024px) {
    .container {
        padding-left: 0;
    }

    .formText {
        font-size: 14px;
    }

    .button {
        height: 44px;
        padding: 8px 16px;
        border-radius: 12px;

        font-size: 14px;
    }
}

@media (max-width:528px) {
    .formContainer {
        width: 100%;
    }
}