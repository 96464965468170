.listingGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}

@media (max-width: 1600px) {
    .listingGrid {
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
    }
}

@media (max-width: 1400px) {
    .listingGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 16px;
    }
}

@media (max-width: 868px) {
    .listingGrid {
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
    }
}