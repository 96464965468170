@import "./smart.css";

html,
body,
#root {
  /* height: 100%; */
}

#root {
  display: flex;
  flex-direction: column;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: "Inter", sans-serif;
  color: #0d0a19;
  list-style: none;
  background-color: #fafafa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}

button {
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  font-size: 10px;
}

@media (min-width: 640px) {
  :root {
    font-size: 12px;
  }
}

@media (min-width: 868px) {
  :root {
    font-size: 16px;
  }
}

/* @media (min-width: 1024px) {
  :root {
    font-size: 20px;
  }
} */

/* @media (min-width: 1440px) {
  :root {
    font-size: 16px;
  }
} */

@media (min-width: 1920px) {
  :root {
    font-size: 20px;
  }
}

@media (min-width: 2560px) {
  :root {
    font-size: 30px;
  }
}

/* react-image-gallery - making thumbnail image responsive */
.image-fit img {
  object-fit: scale-down !important;
}

.invoiceTable .ant-table-tbody > tr > td {
  /* remove bottom border of each row */
  border-bottom: 0 !important;
  /* to decrease height of row */
  padding: 2.5224mm 16px !important;
}

.invoiceTable .ant-table-thead .ant-table-cell {
  /* remove bottom border of heading row */
  border-bottom: 0 !important;
}

.invoiceTable .ant-table-thead .ant-table-cell::before {
  /* remove vertical lines b/w column headings */
  height: 0 !important;
}

.invoiceTable .ant-table {
  /* remove white background of rows */
  background: transparent !important;
}

.invoiceTableHigh .ant-table-tbody > tr > td {
  /* remove bottom border of each row */
  border-bottom: 0 !important;
  /* to decrease height of row */
  padding: 16px !important;
}

.invoiceTableHigh .ant-table-thead .ant-table-cell {
  /* remove bottom border of heading row */
  border-bottom: 0 !important;
}

.invoiceTableHigh .ant-table-thead .ant-table-cell::before {
  /* remove vertical lines b/w column headings */
  height: 0 !important;
}

.invoiceTableHigh .ant-table {
  /* remove white background of rows */
  background: transparent !important;
}

.ant-picker-outlined {
  /* background: #ffffff; */
  /* border-width: 1px; */
  /* border-color: #d9d9d9; */
  width: 280px !important;
  border: none !important;
  background: none !important;
  cursor: pointer;
}

.ant-picker-input {
  cursor: pointer !important;
  font-size: 14px !important;
}

.ant-picker-input > input::placeholder {
  color: #808080 !important;
  font-size: 14px;
  font-family: inter !important;
  font-weight: 700;
}

.invoice-filter-form {
  display: flex;
}

.ant-form-item {
  margin-bottom: 0px;
}

/* b2c home */

.banner-carousel .slick-dots {
  position: absolute !important;
  bottom: 60px !important;
  /* z-index: 9999; */
}

@media(max-width: 1250px){
  .banner-carousel .slick-dots {
    bottom: 40px !important;
  }
}

@media(max-width: 750px){
  .slick-dots li button:before{
    width: 10px;
    height: 10px;
  }

  .banner-carousel .slick-dots {
    bottom: 40px !important;
  }
}

@media(max-width: 600px){
 

  .banner-carousel .slick-dots {
    bottom: 24px !important;
  }
}

/* Style for all dots */
.banner-carousel .slick-dots li button:before {
  font-size: 12px; /* Adjust size of the dots */
  color: white; /* Non-selected dots are white */
  opacity: 1; /* Ensure dots are fully visible */
}

/* Style for the active dot */
.banner-carousel .slick-dots li.slick-active button:before {
  color: blue; /* Selected dot is blue */
}

.detail-carousel .slick-dots li button:before {
  font-size: 12px; /* Adjust size of the dots */
  color: white; /* Non-selected dots are white */
  opacity: 1; /* Ensure dots are fully visible */
}

@media(max-width:650px){
  .banner-carousel .slick-dots li.slick-active button:before {
    color: blue; /* Selected dot is blue */
  }
  
  .detail-carousel .slick-dots li button:before {
    font-size: 8px; /* Adjust size of the dots */
    color: white; /* Non-selected dots are white */
    opacity: 1; /* Ensure dots are fully visible */
  }
}

/* Style for the active dot */
.detail-carousel .slick-dots li.slick-active button:before {
  color: blue; /* Selected dot is blue */
}

.detail-carousel .slick-dots {
  position: absolute !important;
  bottom: 40px !important;
  z-index: 9999;
}

.custom-placeholder .ant-select-selection-placeholder {
  color: black !important;
  height: 52px !important;
  font-size: 16px !important;
  position: absolute;
  font-weight: 500 !important;
  top: 8px;
}

.custom-placeholder {
  width: 25%;
  border-radius: 16px !important;
  height: 52px;
}

@media (max-width: 1250px){
  .custom-placeholder {
    height: 44px !important;
    width: 30% !important;
  }

  .custom-placeholder .ant-select-selection-placeholder {
    margin-top: -3px
  }
}

.banner-carousel
  .ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
    border-radius: 16px !important;
}

.banner-carousel
  :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-single {
  font-size: 16px !important;
  height: 52px !important;
}

.banner-carousel
  :where(.css-dev-only-do-not-override-1ae8k9u).ant-select-outlined:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-radius: 16px !important;
}

.brands-carousel .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

/* slider */

.loan-amount .ant-slider .ant-slider-track {
  background-color: #007fff !important;
  border-radius: 2px;
  height: 10px !important;
}

@media(max-width: 650px){
  .loan-amount .ant-slider .ant-slider-track {
    height: 8px !important;
  }
}

.loan-amount .ant-slider .ant-slider-handle::before {
  content: "";
  position: absolute;
  inset-inline-start: -2px;
  inset-block-start: -2px;
  width: 14px;
  height: 14px;
  background-color: #007fff !important;
  border-radius: 50% !important;
}

.loan-amount .ant-slider .ant-slider-rail {
  background: #fff !important;
  height: 10px !important;
}

.loan-amount .ant-slider .ant-slider-rail {
  border-radius: 50px !important;
}

.amount-checkbox .ant-radio-wrapper .ant-radio-checked::after {
}

.circular-progressbar {
  width: 100%; /* Make the progress bar fill the container */
  height: 100%; /* Make the progress bar fill the container */
}

.login-form .ant-form-item .ant-form-item-label > label {
  color: #222;
  /* Heading/Desktop/H6 */
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin-bottom: 8px;
}

.pass-outer .ant-form-item .ant-form-item-label > label {
  color: #222;
  /* Heading/Desktop/H6 */
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
  margin-bottom: 16px;
  margin-top: 32px;
}

.login-modal {
  position: relative !important;
  border-radius: 32px !important;
}

.login-form
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none !important;
}

.login-form .ant-spin .ant-spin-dot {
  font-size: 30px !important;
  color: white !important;
}

.login-form .ant-modal .ant-modal-content {
  border-radius: 32px !important;
}

.login-modal .ant-modal-close-x {
  color: #007fff; /* Change color to red */
  border-radius: 50%;
  background: var(--tertiary-100, #ecf0ff);
  width: 44px;
  height: 44px;
  position: absolute;
  top: 16px;
  right: 16px;
}

:where(.css-dev-only-do-not-override-1qfezbu).ant-modal .ant-modal-close:hover {
  color: transparent !important;
  background-color: transparent !important;
  text-decoration: none;
}

@media (max-width: 1000px) {
  .login-form .ant-form-item .ant-form-item-label > label {
    font-size: 16px;
  }

  .pass-outer .ant-form-item .ant-form-item-label > label {
    font-size: 16px;
  }
}

@media (max-width: 650px) {
  .login-form .ant-form-item .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600 !important;
  }

  .pass-outer .ant-form-item .ant-form-item-label > label {
    font-size: 14px;
    font-weight: 600 !important;
  }

  .login-modal .ant-modal-close-x {
    width: 27px;
    height: 27px;
    right: 5px;
    top: 5px;
  }
}

.register-otp-container input {
  width: 92px !important;
  height: 92px !important;
  border-radius: 8px;
  border: 1px solid var(--Color-Neutral-neutral-light, #aaa);
  background: var(--Color-Neutral-white, #fff);
  font-size: 24px !important;
  margin-right:0 !important;
}

.register-otp-container{
  gap:8px !important;
  display: flex !important;
  gap: 16px !important;
}


@media (max-width: 650px) {
  .register-otp-container input {
    width: 32px !important;
    height: 32px !important;
    font-size: 18px !important;
  }

  .register-otp-container{
    gap:8px !important;
  }
}

/* .ant-modal{
  display: flex imo !important;
  justify-content: center !important;
  align-items: center !important;
} */

.login-form .react-tel-input .form-control {
  background: #fff;
  border: 1px solid #cacaca;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  padding: 18.5px 14px 18.5px 58px;
  transition: box-shadow .25s ease,border-color .25s ease;
  width: 100%;
}

.login-form .react-tel-input .form-control:hover {
  border-color: #4096ff !important;
}

.auction-slider .slick-slide {
  margin-right: 32px !important; /* Adds gap between slides */
}

.auction-slider .slick-list {
  margin-right: -32px !important; /* Prevents extra margin on the last slide */
}

.auction-slider .slick-dots li button:before {
  font-size: 12px!important;;  /* Adjust size of the dots */
  color: #AAAAAA !important; /* Non-selected dots are white */
  opacity: 1; /* Ensure dots are fully visible */
}

/* Style for the active dot */
.auction-slider .slick-dots li.slick-active button:before {
  color: blue!important;; /* Selected dot is blue */
}

@media(max-width:650px){
  .auction-slider .slick-dots li button:before {
    font-size: 8px!important;;  /* Adjust size of the dots */
  }
  
  /* Style for the active dot */
  .auction-slider .slick-dots li.slick-active button:before {
    color: blue!important; /* Selected dot is blue */
  }
}

