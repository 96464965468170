.sliderDiv {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 32px 8px 48px 8px;
}

.inputsDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.inputsDiv input {
    width: 88px;
    height: 32px;
    border-radius: 8px;
    border: 1px solid #BECEFF;
    background: #FFFFFF;
    padding: 8px;

    color: #444444;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.inputsDiv input:focus {
    outline: none;
    /* Optional: Remove default focus outline */
    border-color: #007FFF;
    /* Optional: Add a focus border color */
}

/* Remove the increase/decrease arrows for number inputs */
.inputsDiv input::-webkit-outer-spin-button,
.inputsDiv input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.inputsDiv input[type="number"] {
    -moz-appearance: textfield;
    /* Remove arrows for Firefox */
}

.text {
    color: #444444;

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}