.pagination {
  display: flex;
  column-gap: 0.813rem;
  align-items: center;
}

.pagination .currentPage {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.375rem;
  background-color: #007FFF;
  color: white;
  font-weight: 700;
  font-size: 0.875rem;
  display: flex;
}

.pagination .currentPage span {
  margin: auto;
}

.pagination p {
  font-weight: 600;
  font-size: 0.938rem;
  /* color: #9a9ea7; */
  color: #000000;
}

.pagination button {
  width: 7.5rem;
  height: 2.5rem;
  border-radius: 0.375rem;
}

.pagination select {
  width: 60px;
  height: 35px;
  color: #000000;
  /* color: #9a9ea7; */
}

@media (min-width: 868px) {
  .pagination select {
    width: 4.5rem;
    height: 2.5rem;
  }

  .pagination button {
    width: 4.75rem;
    height: 2.5rem;
    border-radius: 0.375rem;
  }
}