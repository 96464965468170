.outer {
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
  display: flex;
  gap: 32px;
  align-items: center;
}

.left {
  width: 50%;
}

.imageDiv {
  width: 100%;
  position: relative;
  height: 700px;
}

.loginImage {
  width: 100%;
  border-radius: 34px;
  object-fit: cover;
  height: 700px;
}

.loginLogo {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.5;
}

.loginForm h1 {
  color: #222;
  /* Heading/Desktop/H4 */
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
  margin-bottom: 12px;
}

.startExploring {
  margin-bottom: 48px;
  color: var(--Color-Neutral-neutral-dark, #444);
  /* Text/Regular/Medium */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.loginInput {
  height: 52px;
  font-size: 16px;
  
}

.loginPassword {
  height: 52px;
  font-size: 16px;
  margin-bottom: 12px;
}

.loginSubmit {
  margin-top: 12px;
  padding: 8px 16px;
  border-radius: 16px;
  background: var(--tertiary-400, #007fff);
  color: var(--White, var(--Color-Neutral-white, #fff));
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 52px;
}

.forgot {
  color: var(--Color-Neutral-neutral-dark, #444);
  /* Text/Small/Normal */
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin-top: 48px;
}

.noAccount {
  color: var(--Color-Neutral-neutral-darker, #222);
  /* Text/Regular/Normal */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  margin-top: 24px;
}

.registerNow {
  color: var(--assets, var(--tertiary-400, #007fff));
  /* Text/Regular/Normal */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  cursor: pointer;
}

.formItems{
  display: flex;
  flex-direction: column;
  gap: 32px;
}

@media (max-width: 1000px) {
  .loginForm h1 {
    font-size: 20px;
    margin-bottom: 12px;
  }

  .startExploring{
    margin-bottom: 40px;
  }

  .loginInput,
  .loginPassword {
    height: 44px;
    font-size: 14px;
  }

  .loginPassword {
    margin-bottom: 8px;
  }

  .forgot {
    font-size: 14px;
    margin-top: 40px;
  }

  .loginSubmit {
    margin-top: 8px;
    height: 44px;
  }

  .noAccount {
    margin-top: 12px;
    font-size: 14px;
  }

  .registerNow {
    font-size: 14px;
  }
}

@media (max-width: 650px) {
  .left {
    display: none;
  }

  .right {
    width: 100%;
  }

  .loginForm h1 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .loginInput,
  .loginPassword {
    font-size: 12px;
    height: 40px;
  }

  .loginPassword {
    margin-bottom: 8px !important;
  }

  .forgot {
    font-size: 12px;
  }

  .loginSubmit {
    margin-top: 8px;
    padding: 8px 12px;
    font-size: 12px;
    height: 36px;
  }

  .noAccount {
    margin-top: 8px;
    font-size: 12px;
  }

  .registerNow {
    font-size: 12px;
  }

  .startExploring{
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 32px;
  }

  .formItems{
    gap: 28px;
  }
}
