.searchContainer {
    display: flex;
    margin: 0 64px;
    padding: 64px 0;
}

.filterSection {
    width: 21%;
    display: flex;
    flex-direction: column;
    padding-right: 32px;
    border-right: 1px solid #BECEFF;
}

.headDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
}

.filtersHeadingDiv {
    display: flex;
    gap: 6px;
}

.filtersHeadingDiv h2 {
    color: #444444;
    text-align: center;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.clearAllFilters {
    color: #FF0000;
    text-align: center;

    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;

    cursor: pointer;
}

.filter-menu .ant-menu {
    background-color: transparent !important;
}

.filterWrapper {
    margin: 24px 5px 24px 5px;
}

.listingSection {
    display: flex;
    flex-direction: column;
    padding-left: 32px;
    flex: 1 0;
}

.input {
    width: 100% !important;
    height: 44px !important;
    margin-bottom: 24px;
}

.filterInput {
    width: 100% !important;
    height: 44px !important;
    margin: 24px 5px 24px 5px;
    border-radius: 14px !important;
    border: 1px solid #666666 !important;
    background: #FFFFFF !important;
}

.filterInput .ant-input::placeholder {
    width: 100% !important;
}

.filterInput .ant-input::placeholder {
    color: #444444 !important;
    font-family: "Open Sans" !important;
}


.appliedFiltersWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    margin: 24px 0;
}

.clear {
    color: #FF0000;
    text-align: center;

    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 24px */
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    cursor: pointer;
}

.tagBgGray {
    height: 32px;
    margin-inline-end: 0px !important;
    padding-inline: o !important;
    padding: 10px;
    line-height: 10px !important;
    color: #201B1B;
    text-align: center;

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
    background: #F2F2F2 !important;
}

.listingWrapper {
    margin: 48px 0;
}

.tag {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 6px 8px;

    border-radius: 8px;
    background: #F2F2F2;
}

.tag p {
    color: #222222;
    text-align: center;

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.filterIconMobile {
    width: 14.4px;
    height: 16px;
}

@media (max-width: 1400px) {
    .filterSection {
        width: 25%;
    }
}

@media (max-width: 1100px) {
    .filterSection {
        width: 28%;
    }
}

@media (max-width: 1024px) {
    .tag p {
        font-size: 12px;
        line-height: 150%;
    }

    .searchContainer {
        margin: 0 32px;
        padding: 32px 0;
    }

    .listingSection {
        padding-left: 0;
    }

    .listingWrapper {
        margin: 24px 0;
    }
}