.passOuter {
  padding: 12px;
}

.passOuter h1 {
  color: var(--Color-Neutral-neutral-darker, #222);
  /* Heading/Desktop/H4 */
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
  margin-bottom: 12px;
}

.passOuter p {
  color: var(--Color-Neutral-neutral-dark, #444);
  /* Text/Regular/Medium */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin-bottom: 16px;
}

.loginPassword {
  height: 52px;
  font-size: 16px;
}

.loginSubmit {
  width: 100%;
  height: 52px;
  margin-top: 48px;
  border-radius: 16px;
  color: var(--White, var(--Color-Neutral-white, #fff));
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media(max-width: 1000px){
  .passOuter h1{
    font-size: 24px;
    margin-bottom: 10px;
  }

  .passOuter p{
    margin-bottom: 0;
  }

  .loginSubmit{
    margin-top: 32px;
  }
}

@media(max-width: 650px){
  .passOuter{
    padding: 0;
  }

  .passOuter h1{
    font-size: 18px;
    margin-bottom: 8px;
  }

  .passOuter p{
    font-size: 12px;
  }

  .loginPassword{
    height: 40px;
    font-size: 12px;
  }

  .loginSubmit{
    height: 36px;
    font-size: 12px;
    margin-top: 32px;
  }
}
