.timesBtns {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #007FFF;
  border-radius: 0.375rem;
  margin-bottom: 30px;
}

.timesBtns button {
  width: 10.25rem;
  height: 3rem;
  padding: 0;
  font-size: 0.875rem;
  margin-right: 10px;
}

@media (min-width: 868px) {
  .timesBtns {
    background-color: transparent;
    border-radius: 0;
  }
}
