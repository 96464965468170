.signinForm {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.signinForm p {
  display: flex;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.10000000149011612px;
  text-align: left;
  align-items: center;
  color: #9a9ea7;
}

.signinForm p input {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
}

.signinOptions {
  display: flex;
  justify-content: space-between;
}

.recoverPassword {
  color: #666666;
  font-weight: 600;
  font-size: 0.875rem;
  cursor: pointer;
}

.sumbitButton {
  height: 3.188rem;
  font-size: 1rem;
  font-weight: 700;
  border-radius: 0.625rem;
}

.orText {
  width: 100% !important;
  text-align: center !important;
  justify-content: center;
}

.changeLoginType {
  border-radius: 0.375rem;
  border: none;
  padding: 0.563rem 1.25rem;
  font-family: Inter;
  font-size: 0.875rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  background-color: #fff;
  /* border: 1px solid #f2ad00; */
  border:1px solid #007FFF;
  color: #007FFF;
  cursor: pointer;
}

.signinForm .phoneInput {
  width: 100% !important;
  height: 3.375rem !important;
  font-size: 13px !important;
  height: 4.375rem !important;
}

@media (min-width: 868px) {
  .signinForm .phoneInput {
    font-size: 16px !important;
  }
}