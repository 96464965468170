.filter-menu {
    background-color: transparent !important;
    border-inline-end: none !important;
    width: 100% !important;
}

.filter-menu .ant-menu-inline {
    background-color: transparent !important;
}

.filter-menu .ant-menu-submenu-title {
    background-color: #ECF0FF !important;
    height: 44px !important;
}

.filter-menu .ant-menu-item {
    height: fit-content;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.filter-menu .ant-menu-item:hover {
    background-color: transparent !important;
}

.filter-menu .ant-menu-item-selected {
    color: #FFFFFF !important;
    background-color: transparent !important;
}

/* .filter-menu .ant-menu-submenu, .ant-menu-submenu-title {
    height: 44px !important;
} */

.filter-menu .ant-menu-title-content {
    color: #222222;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}

.search-input {
    border-radius: 14px !important;
    border: 1px solid #666666 !important;
    background: #FFFFFF !important;
}

.search-input .ant-input::placeholder {
    color: #444444 !important;
    font-family: "Open Sans" !important;
}

.password-input {
    height: 52px;
    border-radius: 8px !important;
    border: 1px solid #BECEFF !important;
    background: #FFFFFF !important;
}

.password-input::placeholder {
    color: #444444 !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
}

.password-input .ant-input::placeholder {
    color: #444444 !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
}

.formItem {
    width: 100% !important;
    margin-bottom: 32px !important;
}

.formItem .ant-form-item-label {
    color: #222;

    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
    /* 28px */
}

@media (max-width:1024px) {
    .formItem .ant-form-item-label {

        font-size: 16px;
        /* 28px */
    }

}

.filterInput {
    height: 44px !important;
    border-radius: 14px !important;
    border: 1px solid #666666 !important;
    background: #FFFFFF !important;
}

.filterInput::placeholder {
    color: #444444 !important;
    font-family: "Open Sans" !important;
}

.filter-select {
    height: 44px !important;
}

.filter-select .ant-select-selector {
    border: 1px solid #666666 !important;
    border-radius: 10px !important;
}

.filter-select .ant-select-selection-placeholder {
    color: #444444 !important;
    font-family: "Open Sans" !important;
}

/* Styling the scrollbar track */
.custom-scrollbar::-webkit-scrollbar {
    width: 3px;
    /* Width of the scrollbar */
    height: 3px !important;
    /* Height of the scrollbar (for horizontal scroll) */
}

/* Styling the scrollbar itself */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ECF0FF;
    /* Color of the scrollbar */
    border-radius: 10px;
    /* Rounded corners */
    border: 3px solid #007FFF;
    /* Border around the thumb */
}

/* Styling the scrollbar when it's hovered */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #B0C6FF;
    /* Darker color when hovered */
}

/* Styling the scrollbar track (the background) */
.custom-scrollbar::-webkit-scrollbar-track {
    /* background-color: #D6E4FF; */
    /* Light color for the track */
    /* border-radius: 10px; */
    /* Rounded corners for the track */
}


.ant-tag-close-icon {
    margin-inline-start: 10px !important;
    color: #201B1B !important;
}

.truncate-1-lines {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    /* Number of lines to display */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.descriptions .ant-descriptions-item-label {
    color: #007FFF !important;
    font-weight: 500 !important;
}

.custom-tag .anticon-close {
    color: black !important;
    /* Default color for the close icon */
    transition: color 0.3s;
}

.custom-tag:hover .anticon-close {
    color: red !important;
    /* Hover color for the close icon */
}