.otpOuter {
  padding: 12px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.otpOuter h1 {
  color: var(--Color-Neutral-neutral-darker, #222);
  text-align: center;
  /* Heading/Desktop/H4 */
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
  margin-bottom: 12px;
}

.otpOuter p {
  color: var(--Color-Neutral-neutral-dark, #444);
  text-align: center;
  /* Text/Regular/Medium */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  margin-bottom: 48px;
}

.iAgree {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 48px;
  margin-bottom: 12px;
}

.iAgree p {
  margin-bottom: 0;
  color: var(--Color-Neutral-neutral-dark, #444);
  /* Text/Small/Normal */
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.registerCheck {
  width: 23px;
  height: 23px;
}

.sendButton {
  width: 100%;
  border-radius: 16px;
  background: var(--tertiary-400, #007fff);
  height: 52px;
  color: var(--White, var(--Color-Neutral-white, #fff));
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 24px;
}

.resend {
  color: var(--Color-Neutral-neutral-darker, #222);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.resend span {
  color: var(--assets, var(--tertiary-400, #007fff));
  /* Text/Regular/Normal */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto; */
  cursor: pointer;
}

@media(max-width: 1000px){
    .otpOuter h1{
        font-size: 24px;
        margin-bottom: 10px;
    }

    .otpOuter p{
        margin-bottom: 32px;
    }

    .iAgree{
        margin-top: 32px;
    }

    .iAgree p{
        margin-bottom: 0;
    }
}

@media(max-width: 650px){
    .otpOuter h1{
        font-size: 18px;
        margin-bottom: 8px;
    }

    .otpOuter p{
        margin-bottom: 32px;
        font-size: 12px;
    }

    .iAgree{
        margin-top: 32px;
    }

    .iAgree p{
        margin-bottom: 0;
    }

    .sendButton{
        font-size: 12px;
        border-radius: 16px;
        height: 36px;
        margin-bottom: 12px;
    }

    .resend{
        font-size: 12px;
    }

    .resend span{
        font-size: 12px;
    }

    .otpOuter{
        padding: 0;
    }
}
