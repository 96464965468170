.navOuter {
  padding: 22.5px 64px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
}

.logo {
  cursor: pointer;
}

.navRight {
  display: flex;
  gap: 32px;
  align-items: center;
}

.navButton {
  border-radius: 16px;
  background: #007fff;
  padding: 10px;
  color: #fff;
  display: flex;
  gap: 8px;
  align-items: center;
  height: 44px;
  cursor: pointer;
  color: var(--Color-Neutral-white, #fff);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.navButton img {
  width: 20px;
}

.profile {
  display: flex;
  gap: 16px;
  align-items: center;
  cursor: pointer;
}

.profileLeft p {
  color: #444;
  text-align: right;
  /* Text/Regular/Normal */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.profileLeft span {
  color: #222;
  text-align: right;
  /* Text/Regular/Semi Bold */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

.imageWrapper {
  border-radius: 50%;
  border: 1px solid #ecf0ff;
  width: 52px;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sideMenu {
  position: absolute;
  right: 0;
  top: 0;
  background: #fff;
  padding: 16px;
  height: 100vh;
}

@media (max-width: 1000px) {
  .navOuter {
    padding: 26px 32px;
  }

  .navButton {
    font-size: 14px;
    padding: 8px 16px;
  }

  .profileLeft h4 {
    color: var(--Color-Neutral-neutral-darker, #222);
    text-align: right;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 24px */
  }
}

@media(max-width: 650px){
  .logo{
    width: 100px;
  }

  .navOuter{
    padding: 20px 20px;
  }

  .imageWrapper{
    width: 32px;
  }

  .navRight{
    gap: 12px;
  }

  .auctionMobile{
    width: 20px;
    cursor: pointer;
  }
}
