.container {
    padding-left: 32px;
}

.headSection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
    align-items: center;
}

.listingWrapper {
    margin-top: 64px;
}

.title {
    color: #000000;

    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%;
}

.flex {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.input {
    width: 300px !important;
    height: 44px;
}

@media (max-width:1024px) {
    .container {
        padding-left: 0;
    }

    .title {
        font-size: 18px;
    }

    .listingWrapper {
        margin-top: 32px;
    }
}

@media (max-width:668px) {

    .title {
        font-size: 16px;
    }

    .input {
        width: 200px !important;
        height: 44px;
    }
}