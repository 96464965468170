.brandsDiv {
    display: flex;
    flex-direction: column;
    margin: 27px 4px 48px 4px;
}

.input {
    height: 44px !important;
    margin-bottom: 24px;
}

.text {
    color: #444444;

    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
}

.mb16 {
    margin-bottom: 16px;
}