.outer {
  width: 91%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
}

.left {
  width: 50%;
}

.imageDiv img {
  border-radius: 20px;
  width: 100%;
  height: auto;
}

.imageDiv {
  margin-bottom: 32px;
}

.keyDiv {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 32px;
}

.keyDiv img {
  width: 32px;
}

.keyDiv p {
  color: #222;
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
}

.detailList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 64px;
}

.detailBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #beceff;
}

.propertytitle {
  display: flex;
  gap: 5px;
  align-items: center;
}

.detailBox h3 {
  text-align: center;
  /* Text/Large/Semi Bold */
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  color: #222;
  text-align: left;
}

.inspectionTitle {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 32px;
}

.inspectionTitle img {
  width: 32px;
}

.inspectionTitle p {
  font-family: "Open Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 33.6px */
  color: #222;
}

.inspectionList {
  display: flex;
  gap: 10px;
  flex-direction: column;
}

.inspectionCard {
  border-radius: 12px;
  border: 1px solid #beceff;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.rightContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rightContent p {
  color: #444;
  text-align: center;
  /* Text/Medium/Normal */
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.rightContent h3 {
  text-align: center;
  /* Text/Large/Semi Bold */
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  color: #222;
  text-align: left;
}

.inspectionImage {
  border-radius: 4px;
  background: #ecf0ff;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vehicleDetails {
  padding-bottom: 80px;
  display: flex;
  gap: 32px;
}

.right {
  border-radius: 20px;
  border: 1px solid #beceff;
  height: fit-content;
  padding: 16px;
  width: 50%;
  /* position: fixed;
  right: 0; */
  position: sticky;
  top: 80px;
}

.rightLocation {
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
  color: #222;
  margin-bottom: 12px;
}

.locationDiv {
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;
}

.locationDiv img {
  width: 27px;
}

.locationDiv p {
  color: #222;
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 30px */
}

.right .details {
  display: flex;
  gap: 10px;
  margin-bottom: 24px;
}

.right .detailCard {
  padding: 8px 12px;
  border-radius: 5px;
  background: #ecf0ff;
  display: flex;
  align-items: center;
  gap: 5px;
}

.right .price {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 12px;
}

.right .price img {
  width: 42px;
}

.right .price p {
  color: #222;
  text-align: center;
  /* Heading/Desktop/Tagline */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

.right .price h1 {
  color: #007fff;
  /* Heading/Desktop/H4 */
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
}

.right .price h2 {
  color: #666;
  /* Text/Medium/Medium */
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 27px */
  text-decoration: line-through;
}

.right .emiCard {
  display: flex;
  gap: 8px;
  margin-bottom: 32px;
}

.right .emiCard p {
  color: #222;
  /* Text/Medium/Semi Bold */
  font-family: "Open Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
}

.right .emiCard span {
  color: #007fff;
  /* Text/Large/Semi Bold */
  font-family: "Open Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
}

.right .interest {
  display: flex;
  gap: 8px;
  margin-bottom: 32px;
}

.right .interest img {
  width: 18px;
  height: auto;
  object-fit: contain;
}

.right .interest p {
  color: #222;
  text-align: center;
  /* Heading/Desktop/Tagline */
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
}

.buttonCombo {
  display: flex;
  height: 60px;
  gap: 16px;
}

.showInterest {
  border-radius: 16px;
  /* background-color: #aaa; */
  color: #fff;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  height: 100%;
}

.contactButton {
  border-radius: 16px;
  color: #000;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  cursor: pointer;
  border: 1px solid #111;
}

.exploreMore {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

@media(max-width: 1600px){
  .exploreMore{
    grid-template-columns: repeat(3,1fr);
  }
}

.exploreDiv .title h1 {
  color: #000;
  /* Heading/Desktop/H3 */
  font-family: "Open Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.exploreDiv .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;
}

.viewAll {
  border-radius: 16px;
  border: 1px solid #111;
  background: rgba(255, 255, 255, 0.05);
  padding: 10px 20px;
  color: #000;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  gap: 8px;
  cursor: pointer;
}

.viewAll p {
  color: #000;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.viewAll img {
  width: 20px;
  height: 20px;
}

.detailCard p {
  color: var(--Black, #201b1b);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.absoluteDiv{
  position: relative;
}

.absoluteLogo{
  position: absolute;
  top: 20px;
  left: 20px;
  width: 70px !important;
}

.absolute2{
  position: absolute;
  width: 44px !important;
  right: 16px;
  top: 16px;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .right {
    border-radius: 20px;
    border: 1px solid #beceff;
    height: fit-content;
    padding: 16px;
    width: 100%;
    /* position: fixed;
    right: 0; */
    position: relative;
    top: 0px;
    margin-bottom: 32px;
  }

  .left {
    width: 100%;
  }

  .rightLocation {
    font-size: 24px;
  }

  .detailBox h3{
    font-size: 18px;
  }

  .rightContent p{
    font-size: 16px;
  }

  .exploreDiv .title h1{
    font-size: 32px;
  }

  .exploreDiv .title{
    margin-bottom: 48px;
  }

  .exploreMore{
    grid-template-columns: repeat(2,1fr);
  }
}

@media(max-width: 750px){
  .outer{
    padding-top: 40px;
  }

  .rightLocation{
    font-size: 18px;
    margin-bottom: 8px;
  }

  .locationDiv img{
    width: 20px;
  }

  .locationDiv p{
    font-size: 16px;
  }

  .locationDiv{
    margin-bottom: 16px;
  }

  .right .detailCard{
    padding: 8px;
  }

  .right .detailCard img{
    width: 10px;
  }

  .right .detailCard p{
    font-size: 12px;
  }

  .right .details{
    margin-bottom: 16px;
  }

  .right .price img{
    width: 24px;
  }

  .right .price{
    gap: 8px;
  }

 .right .price h1{
    font-size: 16px;
  }

  .right .price h2{
    font-size: 12px;
  }

  .right .emiCard{
    margin-bottom: 24px;
  }

  .right .interest p{
    font-size: 12px;
  }

  .right .interest img{
    width: 15px;
  }

  .right .interest{
    margin-bottom: 24px;
  }

  .buttonCombo{
    height: 44px;
  }

  .keyDiv p{
    font-size: 18px;
  }

  .keyDiv img{
    width: 24px;
  }

  .detailBox h3{
    font-size: 12px;
  }

  .detailList{
    margin-bottom: 48px;
  }

  .inspectionTitle p{
    font-size: 18px;
  }

  .inspectionTitle img{
    width: 24xp;
  }

  .rightContent p{
    font-size: 12px;
  }

  .inspectionImage{
    width: 36px;
    height: 36px;
  }

  .vehicleDetails{
    padding-bottom: 64px;
  }

  .exploreDiv .title h1{
    font-size: 18px;
  }

  .viewAll{
    padding: 8px 16px;
  }

  .viewAll p{
    font-size: 14px;
  }

  .exploreDiv .title{
    margin-bottom: 32px;
  }

  .exploreMore{
    grid-template-columns: repeat(1,1fr);
  }
}
