.vehicleCard {
    width: 100%;
    height: fit-content;
    /* height: 542px; */
    min-height: 524px;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #BECEFF;
    cursor: pointer;
}

.vehicleCardFit{
    height: fit-content !important;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    overflow: hidden;
    border: 1px solid #BECEFF;
    cursor: pointer;
}

.image {
    width: 100%;
    /* height: 226px; */
    aspect-ratio: 1.6;
    object-fit: cover;
    background-color: #ECF0FF;
    overflow: hidden;
}

.detailSection {
    width: 100%;
    flex: 1 0;
    position: relative;
    padding: 24px 16px 16px 16px;
}

.name {
    overflow: hidden;
    color: #222222;
    /* text-overflow: ellipsis; */

    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;

    margin-right: 45px;
    width: calc(100% - 50px);
}

.priceSection {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 20px;
}

.finalPrice {
    color: #007FFF;

    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
}

.mrpPrice {
    color: #787878;

    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-decoration-line: line-through;
}

.emiText {
    color: #222222;

    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;

    margin-top: 12px;
}

.emiText span {
    font-weight: 600;
}

.dataTags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 20px;
    height: 74px;
}

.tag {
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding: 6px 8px;
    height: 33px;
    border-radius: 8px;
    background: #F2F2F2;
}

.tag p {
    color: #222222;
    text-align: center;

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.buttonsDiv {
    display: flex;
    justify-content: space-between;
    margin-top: 12px !important;
    gap: 16px;
}

.button {
    width: 50% !important;
    height: 44px;
    padding: 10px 20px;
    border-radius: 16px;

    /* color: #FFF; */
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.shareIcon {
    position: absolute;
    top: 24px;
    right: 16px;
    cursor: pointer;
}

.contactContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

@media (max-width: 1050px) {
    .name {
        font-size: 16px;
    }

    .tag{
        height: 29px;
    }

    .finalPrice {
        font-size: 16px;
    }

    .mrpPrice {
        font-size: 14px;
    }

    .emiText {
        font-size: 14px;
        line-height: 150%;

        margin-top: 12px;
    }

    .tag p {
        font-size: 12px;
        line-height: 150%;
    }

    .button {
        height: 44px;
        padding: 8px 16px;
        border-radius: 12px;

        font-size: 14px;
    }

}