.stepCardList {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.stepCardList .stepItem {
  background-color: #007FFF;
  border-radius: 12px;
  width: 13.75rem;
  height: 9.375rem;
  padding: 1rem 0.8rem;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.375rem;
  color: #ffffff;
  margin: auto;
  position: relative;
  margin-right: 20px;
}
.stepCardList .stepItem h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #000000;
}

.stepCardList .stepItem p {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  color: #393939;
}

.stepCardList .stepItem span {
  width: 3.125rem;
  height: 3.125rem;
  background-color: #ffffff;
  border-radius: 50%;
  color: #393939;
  font-size: 1.518rem;
  font-weight: 800;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -1.5rem;
  filter: drop-shadow(0px 5.71429px 5.71429px rgba(0, 0, 0, 0.25));
}

@media (min-width: 1280px) {
  .stepCardList {
    flex-wrap: nowrap;
  }
}
