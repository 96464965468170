.paginationWrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container {
    margin-top: 64px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.inlineFlex {
    display: inline-flex;
}

.pageLink {
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-weight: 600;
    background-color: #ECECEC;
    /* background-color: #BECEFF; */
    cursor: pointer;
}

.active {
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-weight: 600;
    background-color: #007FFF !important;
    color: #ffffff;
}

.active a {
    background-color: #007FFF !important;
}