.outer {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.outer h1 {
  color: var(--Color-Neutral-neutral-darker, #222);
  text-align: center;
  /* Heading/Desktop/H4 */
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
  margin-bottom: 12px;
  width: 417px;
}

.outer p {
  color: var(--Color-Neutral-neutral-dark, #444);
  text-align: center;
  /* Text/Regular/Medium */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.outer h4 {
  width: 417px;
  color: var(--Color-Neutral-neutral-dark, #444);
  text-align: center;
  /* Text/Regular/Medium */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

.outer h3 {
  color: var(--Color-Neutral-neutral-dark, #444);
  /* Text/Small/Normal */
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  margin-top: 48px;
  margin-bottom: 12px;
}

.otpButton {
  border-radius: 16px;
  background: var(--tertiary-400, #007fff);
  display: flex;
  height: 52px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  color: #fff !important;
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.otpButton:hover{
    background: var(--tertiary-400, #007fff) !important;
}

@media(max-width: 1000px){
    .outer h1{
        font-size: 24px;
    }

}

@media(max-width: 650px){
    .outer h1{
        font-size: 18px;
        width: 100%;
    }

    .outer p, .outer h4{
        font-size: 12px;
        width: 100%;
    }

    .outer h3{
        font-size: 12px;
        width: 100%;
    }

    .otpButton{
        font-size: 12px;
        height: 36px;
    }
}
