.register h1 {
  color: var(--Color-Neutral-neutral-darker, #222);
  text-align: center;
  /* Heading/Desktop/H4 */
  font-family: "Open Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 41.6px */
  margin-bottom: 48px;
}

.register {
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.access {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 12px;
}

.access p {
  color: var(--Color-Neutral-neutral-dark, #444);
  /* Text/Small/Normal */
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.dealerButton {
  padding: 8px 16px;
  border-radius: 16px;
  background: var(--tertiary-400, #007fff);
  color: var(--White, var(--Color-Neutral-white, #fff));
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 52px;
  width: 100%;
  margin-bottom: 24px;
}

.orDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 150px;
  margin-bottom: 24px;
}

.leftLine,
.rightLine {
  background-color: #ecf0ff;
  height: 2px;
  width: 100%;
}

.browse p {
  color: var(--Color-Neutral-neutral-dark, #444);
  /* Text/Small/Normal */
  font-family: "Open Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
}

.browse {
  margin-bottom: 12px;
}

.customerButton {
  border-radius: 16px;
  border: 1px solid var(--Color-Neutral-neutral-darkest, #111);
  background: rgba(255, 255, 255, 0.05);
  height: 52px;
  width: 100%;
  color: var(--Color-Neutral-black, #000);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

@media(max-width: 1000px){
    .register h1{
        font-size: 24px;
        margin-bottom: 32px;
    }
}

@media(max-width: 650px){
    .register{
        padding: 0;
    }

    .register h1{
        font-size: 18px;
        width: 80%;
    }

    .access p{
        font-size: 12px;
    }

    .dealerButton{
        font-size: 12px;
        height: 36px;
    }

    .browse p{
        font-size: 12px;
    }

    .customerButton{
        font-size: 12px;
        height: 36px;
    }
}
