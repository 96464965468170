.modal {
    width: 100%;
    height: 100%;
}

.modal .header {
    justify-content: space-between;
    border-bottom: none;
    padding: 2rem 2rem;
}

.wrapper {
    width: 90%;
    margin: auto;
    margin-top: 2rem;
}

.wrapper h1 {
    color: #FF0000;
    font-weight: 500;
    font-size: 0.875rem;
    text-align: center;
}

.wrapper .access {
    display: flex;
    margin-top: 0.875rem;
    align-items: center;
    justify-content: center;
}

.wrapper .access .clickhere {
    /* text-decoration: underline; */
    background-color: #007FFF;
    margin-right: 0.5rem;
    cursor: pointer;
}

.wrapper .access h2 {
    font-weight: 500;
    font-size: 0.875rem;
    text-align: center;
}

@media (min-width: 992px) {
    .modal {
        width: 30rem;
        height: 14rem;
    }

}