.listContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-height: 250px;
    gap: 12px;
    margin: 16px 0 16px 0;
    overflow-y: scroll;
    padding: 5px;
}

.tag {
    height: 32px;
    margin-inline-end: 0px !important;
    padding-inline: o !important;
    padding: 10px;
    line-height: 10px !important;
    color: #201B1B;
    text-align: center;

    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    cursor: pointer;
}

.tagBgGray {
    background: #F2F2F2 !important;
}