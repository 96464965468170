.outer {
  padding: 64px;
  border-top: 1px solid var(--tertiary-200, #beceff);
}

.social {
  display: flex;
  gap: 12px;
  margin-top: 32px;
}

.social img {
  width: 24px;
  cursor: pointer;
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contact h1 {
  color: #000;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 24px */
  margin-bottom: 12px;
}

.contact h2 {
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  color: #000;
  cursor: pointer;
}

.contact h3 {
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  color: #000;
  cursor: pointer;
}

.contact h4{
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  color: #000;
  cursor: pointer;
}

.left {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 48px;
}

.policyDiv {
  display: flex;
  gap: 24px;
}

.policyDiv p {
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: auto;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
  color: #000;
  cursor: pointer;
}

.mobileContact {
  display: flex;
  gap: 48px;
}

.right p {
  color: var(--Text-primary, #000);
  text-align: center;
  font-family: "Open Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 24px */
}

.footerLogo{
  width: 240px;
}

@media (max-width: 1000px) {
  .social img {
    width: 19px;
  }

  .outer {
    padding: 48px 32px;
  }
}

@media (max-width: 750px){
  .footerLogo{
    width: 150px;
  }

  .social{
    margin-top: 16px;
    margin-bottom: 48px;
  }

  .social img{
    width: 15px;
  }

  .left{
    flex-direction: column;
    align-items: flex-start;
  }

  .contact h1{
    margin-bottom: 12px;
  }

  .contact h2{
    font-size: 12px;
  }

  .contact h3{
    font-size: 12px;
  }

  .mobileContact{
    flex-direction: column;
    gap: 32x;
  }

  .right p{
    font-size: 12px;
  }

  
  .outer{
    padding: 32px;
  }
}
