.dashboardNavbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  width: 90%;
  /* padding: 18px 95px; */
  padding: 18px 0px;
}

.dashboardNavbar .info {
  display: none;
  width: 60%;
}

.dashboardNavbar .info h4 {
  color: #f9f9f9;
  font-weight: 500;
  font-size: 0.875rem;
}

.dashboardNavbar .icons svg {
  fill: white;
}

.humburgerButton {
  display: inline-block;
}

@media (min-width: 868px) {
  .dashboardNavbar .info {
    column-gap: 3.438rem;
    display: flex;
    justify-content: center;
    /* margin-left: 10rem; */
  }
  .humburgerButton {
    display: none;
  }
}
